import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerEmailMarketing() {
    var url = ConfigAPI.baseURL + "email-marketing" + Session.getToken();
    return instance.get(url);
  },
  mostrarEmailMarketing(id) {
    var url = ConfigAPI.baseURL + "email-marketing/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEmailMarketing(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "email-marketing" + Session.getToken();
    return instance.post(url,params);
  },
  editarEmailMarketing(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "email-marketing/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarEmailMarketing(id) {
    var url = ConfigAPI.baseURL + "email-marketing/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  cambiarCuentaEmailMarketing(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "email-marketing/"+ data.id + "/key/change" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerEmailMarketingInformation() {
    var url = ConfigAPI.baseURL + "email-marketing-information" + Session.getToken();
    return instance.get(url);
  },
  mostrarEmailMarketingInformation(id) {
    var url = ConfigAPI.baseURL + "email-marketing-information/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEmailMarketingInformation(data, image1, file1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="design") {
        formData.append(key, data[key]);
      }

      if(key=="design") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file1.forEach((value, key) => {          
          formData.append('file', value);
        })
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "email-marketing-information" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarEmailMarketingInformation(data, image1, file1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="design") {
        formData.append(key, data[key]);
      }

      if(key=="design") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }

      if(key=="file") {                
        file1.forEach((value, key) => {          
          formData.append('file', value);
        })
      }
    }

    var params = formData 
    var url = ConfigAPI.baseURL + "email-marketing-information/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarEmailMarketingInformation(id) {
    var url = ConfigAPI.baseURL + "email-marketing-information/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
  obtenerReportLabelCustomerEmailMarketing(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "email-marketing-reports/label-customer" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerReportGeneralSummaryEmailMarketing(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "email-marketing-reports/general-summary" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
